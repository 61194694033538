import React from "react";

const Body = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <h2 className="text-4xl">Coming Soon</h2>
    </div>
  );
};

export default Body;
