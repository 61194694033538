import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-[#1e2124] p-4 text-center font-bold text-white">
      <span>Copyright ASAP DROPS© {currentYear}</span>
    </footer>
  );
};

export default Footer;
