import React from "react";
import logo from "../logo.svg";

const Navbar = () => {
  return (
    <nav className="bg-[#1e2124] dark:bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="https://asap-drops.com/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src={logo} className="h-8" alt="Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap text-white dark:text-[#1e2124]">
            ASAP DROPS
          </span>
        </a>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            type="button"
            className="text-white bg-[#7289da] hover:bg-[#5c6eaf] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center"
          >
            Join Discord
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
